import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Layout from '../../components/layout'
import Seo from '../../components/Seo'
import StructuredData from '../../components/structured-data'

function Postings({ data }) {
  const { wpPage, hero } = data

  return (
    <Layout>
      <Seo post={wpPage} />
      <StructuredData pageType="webPage" />
      <div className="mb-10 lg:mb-16 py-10 px-5 lg:py-24 relative text-white">
        <div className="container relative z-30">
          <h1 className="text-white">Careers at Liquid Web</h1>
          <div role="doc-subtitle" className="py-1 text-3xl font-semibold">
            We&apos;re Looking for People Just Like You
          </div>
        </div>
        <div
          className="absolute w-full h-full top-0 left-0 z-20"
          style={{
            backgroundImage: `linear-gradient(90deg, #64375b 0%, rgba(46,60,75,0.72) 100%)`,
          }}
          role="presentation"
        ></div>
        <GatsbyImage
          image={hero.childImageSharp.gatsbyImageData}
          alt="Careers at Liquid Web"
          className="w-full h-full left-0 top-0 z-10"
          style={{ position: `absolute` }}
        />
      </div>
      <div className="container">
        <h3>Current Job Openings</h3>
        <div className="py-3 text-lg uppercase font-semibold">
          FOR ALL LOCATIONS
        </div>
        <div className="pb-10">
          <iframe
            style={{ height: '500px' }}
            name="liquidweb-job-openings"
            id="liquidweb-job-openings"
            src="https://jobs.jobvite.com/liquidweb"
            width="100%"
          ></iframe>
        </div>
      </div>
      <div className="text-white" style={{ backgroundColor: `#64375b` }}>
        <div className="container py-4 text-center">
          <Link to="/kb/" className="text-white hover:text-white">
            Visit Our Industry-Leading Knowledge Base
          </Link>
        </div>
      </div>
    </Layout>
  )
}

Postings.propTypes = {
  location: PropTypes.object,
  data: PropTypes.object,
}

export default Postings

export const query = graphql`
  {
    wpPage(databaseId: { eq: 5635 }) {
      title
      date
      uri
      modified
      uri
      chatDepartment
      seo {
        ...postSeoFields
      }
    }
    hero: file(relativePath: { eq: "careers/careers-postings.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          placeholder: TRACED_SVG
          layout: FULL_WIDTH
        )
      }
    }
  }
`
